<template>
  <v-row>
    <v-col>
      <v-row no-gutters class="justify-center my-5">
        <v-col>
          <v-row>
            <v-col>
              <h1 class="text-center text-uppercase primary--text">
                Menunggu Mendapatkan Produk Gratis
              </h1>
            </v-col>
          </v-row>

          <flip-countdown
            v-if="event.deadline"
            :labels="countdown.labels"
            :deadline="event.deadline"
          ></flip-countdown>

          <v-row v-show="isCounting == false">
            <v-col>
              <v-card>
                <v-card-title class="d-flex justify-center">
                  <v-alert prominent text color="success">
                    Selamat Kepada Para Pemenang
                  </v-alert>
                </v-card-title>
                <v-card-text>
                  <v-data-table
                    :headers="table.headers"
                    :items="table.items"
                    :search="table.search"
                    :item-class="itemRowBg"
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="table.search"
                        label="Search"
                        class="mx-4"
                      ></v-text-field>
                    </template>
                    <template v-slot:item.phone="{ item }">
                      {{ item.phone | hide }}
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog persistent width="500" v-model="additionalPrize">
      <v-card>
        <v-card-title>
          <strong>Surprise!!</strong>
        </v-card-title>
        <v-card-text>
          <p>
            Bosan nunggu kan?
            <br />
            Ada hadiah lainnya nih. Mau Ga?
          </p>
          <v-row>
            <v-col>
              <v-btn
                block
                depressed
                color="primary"
                @click="challenge.show = true"
              >
                <span>ya, saya mau</span>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn block text @click="additionalPrize = false">
                <span>ga dulu deh</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent max-width="800" v-model="challenge.show">
      <v-card>
        <v-card-title>
          <v-sheet v-if="challenge.data" outlined max-width="100%" rounded="lg">
            <v-skeleton-loader
              v-if="challenge.loading"
              type="image"
            ></v-skeleton-loader>
            <v-img
              v-if="challenge.loading == false"
              max-height="500"
              contain
              src="@/assets/promo/q_2.png"
            ></v-img>
          </v-sheet>
        </v-card-title>
        <v-card-text>
          <h3>Tebak Gambar Diatas</h3>
          <br />
          <v-form ref="question">
            <v-text-field
              dense
              outlined
              label="Jawaban"
              v-model="challenge.answer"
            >
            </v-text-field>
          </v-form>
          <v-btn
            block
            depressed
            color="accent"
            @click="answer"
            :loading="challenge.checkAnswer"
            :disabled="challenge.checkAnswer"
          >
            <span>cek Jawaban</span>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="form.bank_account.show" max-width="800" persistent>
      <v-card>
        <!-- <v-toolbar tile flat>
          <v-spacer></v-spacer>
          <v-btn icon color="error" @click="closePopUp">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar> -->
        <div v-if="challenge.isCorrect">
          <v-card-title v-if="form.submited"
            >Asik.. Dapet duit nih</v-card-title
          >
          <v-card-text v-if="form.submited">
            <p>Selamat yah! Kamu dapat Rp.100.000</p>
            <p>
              Silahkan tutup pesan ini untuk kembali menunggu pengumumam hadiah
              utama
            </p>
          </v-card-text>
          <v-card-title v-if="form.submited == false">
            JAWABAN KAMU BENAR!!
          </v-card-title>
          <v-card-text v-if="form.submited == false">
            <p>
              Sekarang isi data dibawah agar kami bisa mengirimkan hadiah
              secepatnya
            </p>
            <v-form ref="bankAccount">
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    label="Nama Bank"
                    v-model="form.bank_account.data.bank_name"
                    :rules="form.bank_account.rules.bank_name"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    label="No. Rekening"
                    v-model="form.bank_account.data.account_number"
                    :rules="form.bank_account.rules.account_number"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    label="Nama Pemilik"
                    v-model="form.bank_account.data.account_name"
                    :rules="form.bank_account.rules.account_name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="saveBankAccount">
                  <span>submit</span>
                  <v-icon right>mdi-send</v-icon>
                </v-btn>
              </v-row>
            </v-form>
          </v-card-text>
        </div>
        <div v-if="challenge.isCorrect == false">
          <v-card-title>Kamu Sudah Mencoba!!!</v-card-title>
          <v-card-text>
            <h3>Tetap Semangat Ya!!</h3>
            <br />
            <p>Silahkan Kembali Menunggu Untuk Mendapatkan Produk Gratis.</p>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog persistent width="auto" max-width="600" v-model="win">
      <v-img src="@/assets/promo/selamat-01.png">
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-btn icon @click="win = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-img>
    </v-dialog>

    <v-dialog v-model="lose" persistent width="auto" max-width="600">
      <v-card>
        <v-toolbar tile flat>
          <v-spacer></v-spacer>
          <v-btn icon @click="lose = false" color="error">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title> Yah.. Kamu kurang cepet nih :( </v-card-title>
        <v-card-text>
          <h3>Jangan patah semangat ya!</h3>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="load_data" width="300">
      <v-alert prominent dense class="mb-0">
        <v-col>
          <p>Please Wait, Loading...</p>
          <v-progress-linear indeterminate></v-progress-linear>
        </v-col>
      </v-alert>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";
import "moment-timezone";
import FlipCountdown from "vue2-flip-countdown";
import axios from "axios";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      win: false,
      lose: false,
      load_data: false,
      now: null,
      isCounting: false,
      additionalPrize: false,
      challenge: {
        answer: null,
        show: false,
        checkAnswer: false,
        isCorrect: null,
        data: null,
        loading: false,
      },
      countdown: {
        labels: {
          days: "Hari",
          hours: "Jam",
          minutes: "Menit",
          seconds: "Detik",
        },
      },
      form: {
        loading: false,
        submited: false,
        bank_account: {
          show: false,
          data: {
            account_name: null,
            account_number: null,
            bank_name: null,
          },
          rules: {
            account_name: [(v) => !!v || "Nama tidak boleh kosong"],
            account_number: [(v) => !!v || "No. Rekening tidak boleh kosong"],
            bank_name: [(v) => !!v || "Nama Bank tidak boleh kosong"],
          },
        },
      },
      event: {
        deadline: null,
        timezone: null,
      },
      table: {
        loading: false,
        show: false,
        search: null,
        headers: [
          { text: "No.", value: "id" },
          { text: "Nama", value: "name" },
          { text: "No. Handphone", value: "phone" },
          { text: "Alamat", value: "address" },
        ],
        items: [],
      },
    };
  },
  methods: {
    ...mapActions(["addBankAccount"]),
    itemRowBg(item) {
      let mod = item.id % 2;
      if (mod == 0) {
        return "blue-grey lighten-4";
      }
    },

    closePopUp(args) {
      switch (args) {
        case "challenge":
          this.form.bank_account.show = false;
          this.challenge.show = false;
          this.challenge.checkAnswer = false;
          this.additionalPrize = false;
          break;
        case "win":
          this.win = false;
          break;
        case "lose":
          this.lose = false;
          break;
      }
    },

    interval() {
      this.isCounting = true;
      // console.log(this.isCounting);
      let s = 0;
      let countdown = setInterval(() => {
        this.now = moment().local().format("YYYY-MM-DD HH:mm:ss");
        s += 1;
        // console.log(s);
        if (s == 300) {
          this.additionalPrize = true;
        } else if (this.now >= this.event.deadline) {
          this.getWinStatus().then((res) => {
            if (res) {
              this.win = true;
            } else {
              this.lose = true;
            }
          });
          this.isCounting = false;
          clearInterval(countdown);
          this.getWinners();
        }
      }, 1000);
    },

    async getQuestion() {
      let res = await axios.get(
        `${process.env.VUE_APP_KB_API_URL}/api/challenges/event/${this.$route.query.event_id}`
      );

      this.challenge.data = res.data;
      this.challenge.loading = true;
      await axios
        .get(
          `${process.env.VUE_APP_KB_API_URL}/api/challenges/image/${res.data.id}`
        )
        .then((response) => {
          this.challenge.data.image = response.data;
          this.challenge.loading = false;
        });
    },

    async answer() {
      this.challenge.checkAnswer = true;

      await axios
        .post(
          `${process.env.VUE_APP_KB_API_URL}/api/challenges/event/${this.$route.query.event_id}`,
          {
            answer: this.challenge.answer,
          }
        )
        .then((response) => {
          let result = response.data.message;
          if (result == "correct") {
            this.challenge.isCorrect = true;
            this.form.bank_account.show = true;
            this.challenge.checkAnswer = false;
          } else {
            this.form.bank_account.show = true;
            this.challenge.isCorrect = false;
            this.challenge.checkAnswer = false;
          }
        });
    },

    saveBankAccount() {
      this.form.loading = true;
      let data = this.form.bank_account.data;
      let isValid = this.$refs.bankAccount.validate();
      data["participant_id"] = this.$route.query.participant_id;
      if (isValid) {
        if (this.addBankAccount(data)) {
          this.form.loading = false;
          this.form.submited = true;
        }
      } else {
        this.form.loading = false
      }
    },

    async getWinStatus() {
      let event_id = this.$route.query.event_id;
      let participant_id = this.$route.query.participant_id;
      let is_winning = false;

      await axios
        .get(
          `${process.env.VUE_APP_KB_API_URL}/api/participants/${event_id}/${participant_id}`
        )
        .then((response) => {
          if (response.data == 1) {
            is_winning = true;
          } else {
            is_winning = false;
          }
        })
        .catch((err) => {
          console.error(err);
        });
      console.log(is_winning);
      return is_winning;
    },
    async getWinners() {
      await axios
        .get(
          `${process.env.VUE_APP_KB_API_URL}/api/participants/winners/${this.$route.query.event_id}`
        )
        .then((response) => {
          this.table.items = response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  components: {
    FlipCountdown,
  },
  filters: {
    hide: (num) => {
      let arr = num.toString().split("");
      let newArr = [];
      for (let i = 0; i < arr.length - 4; i++) {
        newArr.push("X");
      }

      let newArr2 = arr.slice(arr.length - 4);
      return newArr.concat(newArr2).join("");
    },
  },
  watch: {
    "challenge.isCorrect": function (val) {
      if (val === false) {
        setTimeout(() => {
          this.closePopUp("challenge");
        }, 7000);
      }
    },
    "form.submited": function (val) {
      if (val === true) {
        setTimeout(() => {
          this.closePopUp("challenge");
        }, 3000);
      }
    },
    win: function (val) {
      if (val === true) {
        setTimeout(() => {
          this.closePopUp("win");
        }, 20000);
      }
    },
    lose: function (val) {
      if (val === true) {
        setTimeout(() => {
          this.closePopUp("lose");
        }, 20000);
      }
    },
  },
  mounted() {
    this.getQuestion();
    this.getWinStatus();
    this.getWinners();

    let deadline = this.$route.query.deadline;
    this.event.timezone = this.$route.query.timezone;
    let tz = moment.tz.guess();
    let time = moment.tz(deadline, this.event.timezone);
    this.now = moment().local().format("YYYY-MM-DD HH:mm");
    this.event.deadline = time.tz(tz).format("YYYY-MM-DD HH:mm");

    console.log(this.event.deadline);
    // console.log(this.event.deadline);
    if (this.now >= this.event.deadline) {
      this.isCounting = false;
    } else {
      this.interval();
    }
  },
};
</script>